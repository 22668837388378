.nav-bar {
    background-color: var(--audette-black);
    overflow: hidden;
    position: fixed;
    z-index: 2000;
    width: 100%;
    height: 60px;
}

.ant-layout-header { padding: 0 }

.menu {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

.menu-item {
    height: 100%;
    padding: 0 var(--size-s);
    text-align: center;
    cursor: pointer;
}

.menu-item-active {
    background-color: var(--audette-hot-pink);
    height: 100%; 
    padding: 0 var(--size-s);
    text-align: center;
}

.menu-last-item {
    color: var(--audette-white);
    marginLeft: "auto";
    flex-grow: 2;
    top: "5px";
    display: flex;
    justify-content: end;
    align-items: center;
    paddingRight: 20px;
}
