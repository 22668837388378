.floating-kpi {
    background-color: #fff;
    z-index: 100;
    position: absolute;
    right: 20px;
    bottom: 30px;
    box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.05);
}
.floating-kpi > *:not(:last-child) {
    border-bottom: 1px solid rgb(0, 0, 0, 10%);
}

.floating-kpi > * {
    padding: 10px 16px;
}

.title { 
    padding-bottom: 3px;
}


.value {
    font-weight: 600;
    transform: rotateX(180deg);
}

.units {
    margin-left: 5px;
    font-size: 12px;
}
