.aud-kpi-callout {
    padding: var(--size-s);
    border-radius: 3px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.aud-tooltip {
    position: fixed;
    background-color: white;
    border: 1px solid orange;
    color: orange;
    padding: 10px;
    z-index: 10000;
    transition: left 0.7s, top 0.7s;
    transition-timing-function: ease-out;
}
