.ant-card-extra > a {
    color: #EB03AD;
    font-weight: 700;
}

.comparision-tag-group .audette-tag {
    display: inline-block;
}

.comparision-tag-group .audette-tag:first-child {
    margin-left: 0px;
}

.comparision-tag-group .audette-tag:last-child {
    margin-right: 0px;
}
