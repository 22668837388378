.aud-insight-tooltip {
    padding: var(--size-xs);
    background-color: var(--audette-white);
    border-radius: 3px;
    box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.15);
}

.aud-insight-tooltip-group:not(:last-child) {
    margin-bottom: var(--size-xs);
}

.aud-label {
    color: var(--secondary-font-color);
    font-size: 0.9em;
    margin-bottom: -4px;
}
