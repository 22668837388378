.aud-tag {
    font-weight: bold;
    padding-top: 2px !important;
    border-radius: var(--size-xs);
    box-sizing: border-box;
    height: var(--size-s);
    padding: 0px var(--size-xs);
    top: 1px;
    font-size: 12px;
    margin: 0px 15px;
    display: table-cell;
    vertical-align: middle;
}

.ant-tag-close-icon {
    margin-left: 5px;
    font-size: 8px;
}

.ant-tag-has-color .anticon-close {
    color: inherit;
}

.ant-tag-has-color .anticon-close, .ant-tag-has-color .anticon-close:hover {
    color: inherit;
}
 
