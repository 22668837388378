:root {
    --audette-hot-pink: #EB03AD;
    --audette-hot-pink-secondary:  #C10098;
    --audette-light-pink:  #EF81DA;
    --audette-light-pink-secondary: #F7C1EC;

    --audette-purple: #7700ff;
    --audette-purple-secondary: #A76BFC;
    --audette-light-purple: #D5BBF9;

    --audette-orange:   #F94646;
    --audette-orange-secondary:   #F9A9A7;
    --audette-light-orange: #faedeb;

    --audette-carrot-orange: #F7931E;
    --audette-carrot-orange-secondary: #F9BF73;
    --audette-light-carrot-orange: #F2E2B6;

    --audette-teal:  #00A081;
    --audette-teal-secondary:  #00BC98;
    --audette-light-teal:  #4BF7B5;
    --audette-light-teal-secondary:  #CCFAE5;

    --audette-green:   #50E253;
    --audette-green-secondary:   #39B54A;
    --audette-light-green:   #A4F4A4;

    --audette-blue:   #066ECC;    
    --audette-blue-secondary:   #7CB7F2;
    --audette-light-blue:   #D3DFF2;

    --audette-concrete: #e3e5de;
    --audette-black: #000000;
    --audette-white: #ffffff;

    --secondary-font-color: #666;
}

@font-face {
    font-family: 'NimbusSan-Reg';
    src: local('NimbusSan-Reg'), url(./assets/fonts/NimbusSan-Reg.otf) format('opentype');
}

@font-face {
    font-family: 'HarmoniaSansPro-Bold';
    src: local('HarmoniaSansPro-Bold'), url(./assets/fonts/HarmoniaSansPro-Bold.otf) format('opentype');
}



