.legend-colour {
    width: var(--size-s);
    height: var(--size-s);
    margin-right: var(--size-xs);
}

li {
    display: flex;
    padding-top: var(--size-xxs);
}

ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: var(--size-xs);
}
    
