.aud-button {
    font-family: Harmonia Sans Pro, Helvetica, Arial, sans-serif;
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    height: auto;
    text-align: center;
}

.aud-button--primary {
    color: var(--audette-white);
    background-color: var(--audette-hot-pink);
    border: 1px solid var(--audette-hot-pink);
}
.aud-button--primary:hover {
    color: var(--audette-black);
    border-color: var(--audette-black);
}

.aud-button--secondary {
    background-color: transparent;
    border: 1px solid var(--audette-black);
}
.aud-button--secondary:hover {
    color: var(--audette-hot-pink);
    border-color: var(--audette-hot-pink);
}

.aud-button--small {
    font-size: 12px;
    line-height: 20px;
    padding: 4px 8px;
}
.aud-button--medium {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
}
.aud-button--large {
    font-size: 18px;
    line-height: 26px;
    padding: 14px 28px;
}




