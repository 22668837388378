.width-300 {
    width: 300px;
}

.complex-option {
    display:
}

.ant-select-multiple .ant-select-selection-item {
    border: none;
    background-color: inherit;
}

