
.drawer-show {
    display: box-border;
    position: relative;
    width: var(--size-xxl);
    z-index: 1000;
    box-shadow: calc(0.5 * var(--size-xs)) 0px var(--size-xs) 0px var(--audette-black);
    background-color: var(--audette-white);
    transition: width 1s;
}

.drawer-show-large {
    display: box-border;
    position: relative;
    width: var(--size-xxxl);
    z-index: 1000;
    box-shadow: calc(0.5 * var(--size-xs)) 0px var(--size-xs) 0px var(--audette-black);
    background-color: var(--audette-white);
    transition: width 1s;
}

.drawer-hide {
    display: box-border;
    position: relative;
    overflow-x: hidden;
    width: 0px;
    z-index: 1000;
    transition: width 0.7s, box-shadow 0.7s;
    background-color: var(--audette-white);
    transition-timing-function: ease-in-out;
}

.drawer-content {
    background-color: var(--audette-white);
    padding: var(--size-s);
    width: var(--size-xxl);
    position: fixed;
    height: calc(100vh - 60px);
    overflow-y: auto;
}

.drawer-content-large {
    background-color: var(--audette-white);
    padding: var(--size-s);
    width: var(--size-xxxl);
    position: fixed;
    height: calc(100vh - 60px);
    overflow-y: auto;
}

.drawer-content > *:not(:last-child), .drawer-content-large > *:not(:last-child){
    margin-bottom: var(--size-s);
}

.drawer-insight {
    width: 100%;
    height: var(--size-xl);
}
