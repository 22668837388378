@font-face {
    font-family: 'NimbusSan-Reg';
    src: local('NimbusSan-Reg'), url(../../../assets/fonts/NimbusSan-Reg.otf) format('opentype');
}

@font-face {
    font-family: 'HarmoniaSansPro-Bold';
    src: local('HarmoniaSansPro-Bold'), url(../../../assets/fonts/HarmoniaSansPro-Bold.otf) format('opentype');
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'HarmoniaSansPro-Bold'
    
}

p {
    font-family: 'NimbusSan-Reg'
}

.audette-text-small {
    font-size: 10px;
}

.audette-text-medium {
    font-size: 12px;
}

.audette-text-large {
    font-size: 14px;
    font-weight: bold;
}

.audette-title-small {
    font-size: 18px !important;
    font-variant: all-small-caps;
    letter-spacing: 1.4px;
}

.audette-title-medium {
    font-size: 22px !important;
}

.audette-title-large {
    font-size: 30px !important;
}
