.ant-popover-inner {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 10px !important;

    .ant-popover-title {
        text-align: center !important;
    }
}
