html {
    margin: 0;

    --audette-hot-pink: #EB03AD;
    --audette-hot-pink-secondary:  #C10098;
    --audette-light-pink:  #EF81DA;
    --audette-light-pink-secondary: #F7C1EC;

    --audette-purple: #7700ff;
    --audette-purple-secondary: #A76BFC;
    --audette-light-purple: #D5BBF9;

    --audette-orange:   #F94646;
    --audette-orange-secondary:   #F9A9A7;
    --audette-light-orange: #faedeb;

    --audette-carrot-orange: #F7931E;
    --audette-carrot-orange-secondary: #F9BF73;
    --audette-light-carrot-orange: #F2E2B6;

    --audette-teal:  #00A081;
    --audette-teal-secondary:  #00BC98;
    --audette-light-teal:  #4BF7B5;
    --audette-light-teal-secondary:  #CCFAE5;

    --audette-green:   #50E253;
    --audette-green-secondary:   #39B54A;
    --audette-light-green:   #A4F4A4;

    --audette-blue:   #066ECC;    
    --audette-blue-secondary:   #7CB7F2;
    --audette-light-blue:   #D3DFF2;

    --audette-concrete: #e3e5de;
    --audette-black: #000000;
    --audette-white: #ffffff;

    --full-width: 90%;

    --size-xxxl: 480px;
    --size-xxl:  320px;
    --size-xl:   160px;
    --size-l:    80px;
    --size-m:    40px;
    --size-s:    20px;
    --size-xs:   10px;
    --size-xxs:  5px;

    background-color: var(--audette-white);
}


#root {
    min-height: 100vh;
}

.ant-layout {
    min-height: 100vh;
    background-color: var(--audette-white);
}

.insight {
    height: 540px;
    background: var(--audette-white);
    width: var(--full-width);
    margin: auto;
    margin-top: var(--size-m);
    margin-bottom: var(--size-l);
}
.insight-short {
    height: var(--size-xl);
}

.flex-row {
    width: 100%;
    display: flex;
    align-items: baseline;
    flex-direction: row;
}
.flex-column {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flex-grow-2 {
    flex-grow: 2;
}
.flex-resize {
    flex-grow: 2;
    flex-shrink: 2;
}
.flex-stretch {
    justify-content: stretch;
}
.flex-space-around {
    justify-content: space-around;
}
.flex-align-start {
    align-items: start;
}
.flex-align-stretch {
    align-items: stretch;
}
.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.margin-bottom-s {
    margin-bottom: var(--size-s);
}

.width-100p {
    width: 100%;
}
.height-100p {
    height: 100%;
}
.full-page-height {
    height: calc(100vh - 60px);
}

.position-absolute { position: absolute }
.overflow-hidden { overflow-y: hidden }

.ant-select-selection-placeholder {
    color: #000000;
}
