.heatmap-control {
    width: 25%;
    max-width: 330px;
    position: fixed;
    top: 80px;
    right: 20px;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.heatmap-legend {
    display: flex;
    row-gap: 6px;
    flex-direction: column;
    text-align: center;

    &-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 5px;
        white-space: nowrap;
    }
}

.colors {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    width: 100%;
}

.color {
    height: 12px;
}

.ant-dropdown-trigger {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--audette-black);

    &:hover {
        color: var(--audette-purple);
    }
}

.ant-dropdown-menu-item-selected {
    color: var(--audette-black);
    background-color: var(--audette-light-purple);
}

.ant-dropdown-menu-item-selected:hover {
    background-color: var(--audette-light-purple);
}
