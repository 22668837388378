.ant-card-head {
    background: #E3E5DE;
}

.ant-card-head-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
}

.card-tag-group .audette-tag { 
    display: inline-block;
    margin: 8px 16px 8px 0px;
}